export const CIRCLE_CI_REGEX = /^https:\/\/app.circleci.com\/pipelines\/[^/]+\/[^/]+\/[^/]+$/;
export const GITLAB_TOP_LEVEL_REGEX = /^https:\/\/gitlab.com\/(?!groups).+([^/]|$)+$/;
export const SLACK_REGEX = /^(http|https):\/\/(.*).slack.com+$/;
export const PIPERMAIL_REGEX = /^(http|https):\/\/(.*)\/[^/]+\/[^/]+$/;
export const BUGZILLA_REGEX = /^(http|https):\/\/(.*)+$/;
export const EMAIL_REGEX =
  /^[A-Za-z0-9._%+-]+@[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?(?:\.[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*\.[A-Za-z]{2,}$/;
export const GIT_REGEX = /^(http|https|git):\/\/git[.].+$/;
export const SUBDOMAIN_REGEXP = /^[a-zA-Z0-9]+(?:[a-zA-Z0-9-]+[a-zA-Z0-9])?(?:\.[a-zA-Z0-9]+(?:[a-zA-Z0-9-]+[a-zA-Z0-9])?)*$/;
export const BRAND_COLOR_REGEX = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
